.about {
    .about1 {
        margin-top: -130px;
        background-color: #3d7db3;
        padding-top: 140px !important;

        @media (max-width: 575.98px) {
            margin-top: -50px;
            padding-top: 70px !important;
        }
    }

    .about2 {
        background-color: #7b8ea1;

        .department-title {
            border-radius: 8px;
            background-color: rgba(0, 0, 0, .1);
            padding: 5px 10px;
        }

        .image-staff {
            width: 165px;
            border: 5px solid rgba(255,255,255,0.5);
            border-radius: 50%;
            box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
        }
    }
}