$success-color: #0099FF;
$cancel-color: #AAA;

.swal2-styled {
    @extend %button-circle;

    transition: all 0.2s ease;
    height: 40px !important;
    margin: 0 2% !important;
	padding: 0 20px !important;
    line-height: 0 !important;
    width: auto !important;
    min-width: 100px;
    background-color: #FFF !important;
    box-shadow: 0 2px 3px rgb(0 0 0 / 30%);

    &.swal2-confirm {
        border: 1px solid $success-color !important;
        color: $success-color !important;

        &:hover {
            background-color: $success-color !important;
        }
    }

    &.swal2-cancel {
        border: 1px solid $cancel-color !important;
        color: $cancel-color !important;

        &:hover {
            background-color: $cancel-color !important;
        }
    }

    &:hover {
        color: #FFF !important;
    }
}

.swal2-select {
    border-radius: 8px;
}