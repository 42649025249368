$first-color: #0099FF;
$second-color: #387DC0;
$third-color: #30669c;

a {
    color: $second-color;
}

.btn-primary {
    background-color: $second-color;
    border-color: $second-color;

    &:hover {
        background-color: $third-color;
        border-color: $third-color;
    }
}

.btn-outline-primary {
    color: $second-color;
    border-color: $second-color;

    &:hover {
        background-color: $third-color;
        border-color: $third-color;
    }
}