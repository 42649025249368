.navbar {
    background-color: transparent;

    &.scrolled {
        background-color: #F8F9FA;
    }

    &.shrink {
        box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
        margin: 2;

        .navbar-brand {
            margin: 0;
	        padding: 0;

            img {
                height: 39px;
                filter: brightness(100%);
            }
        }

        .navbar-toggler {
            color: #3085D6 !important;
        }

        .nav-link {
            color: #636363 !important;
            margin: 0;
            padding: 5px;

            &:hover {
                border: 1px solid rgba(0, 0, 0, .1);
                color: #3085D6 !important;
                background-color: rgba(255, 255, 255, 0);
            }
        }
    }

    .navbar-brand {
        img {
            filter: grayscale(100%) brightness(300%);
        }
    }

    .navbar-toggler {
        transition: unset;
        border: none !important;
        color: #FFF !important;
        font-size: 28px;
    }

    .nav-link {
        color: #E1E1E1 !important;

        &:hover {
            border: 1px solid rgba(255, 255, 255, .3);
            color: #28A745 !important;
            background-color: rgba(0, 0, 0, .4);
        }
    }

    .dropdown {
        transition: .2s ease;

        .dropdown-menu {
            padding: 2px;
            transition: .2s ease;
            color: #636363;

            .dropdown-item {
                &:hover {
                    background-color: rgba(0, 0, 0, .1);
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        .navbar-collapse {
            background-color: #F8F9FA !important;
        }

        .nav-link {
            color: #636363 !important;
        }
    }
}