@font-face {
  font-family: calibri;
  src: url("fonts/Calibri.ttf");
}
@font-face {
  font-family: cabin;
  src: url("fonts/CabinSketch-Regular.otf") format("opentype");
}
* {
  transition: all 0.2s;
}

html, body {
  font-family: calibri;
  font-size: 18px;
}

.opacity-0 {
  opacity: 0;
}

.scale-0 {
  transform: scale(0);
}

article {
  background-color: #ECF0F1;
}

.mn-bg-blue {
  background-color: #2CB7F0;
}

.mn-bg-blue-dark {
  background-color: #0F98E7;
}

.mn-big-number {
  font-size: 50px;
}

.mn-bg-icons {
  background-color: #85D0F5;
}

.btn-table {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
  .animated-checkbox.checkbox-xl-2 input[type=checkbox] + .label-text:before {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .animated-checkbox.checkbox-xs-2 input[type=checkbox] + .label-text:before {
    font-size: 30px;
  }
}
form {
  margin-block-end: 0;
}

.form-group.required label:after {
  content: "*";
  color: red;
  font-weight: bold;
}

.g-recaptcha {
  display: inline-block;
}

.filter-footer {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.bg-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1050;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  text-align: center;
  transition: 0.3s ease;
}
.bg-overlay img {
  position: fixed;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-overlay-show {
  visibility: visible;
  opacity: 1;
}

label {
  width: max-content;
}

a {
  color: #387DC0;
}

.btn-primary {
  background-color: #387DC0;
  border-color: #387DC0;
}
.btn-primary:hover {
  background-color: #30669c;
  border-color: #30669c;
}

.btn-outline-primary {
  color: #387DC0;
  border-color: #387DC0;
}
.btn-outline-primary:hover {
  background-color: #30669c;
  border-color: #30669c;
}

.swal2-styled, .btn-circle {
  width: auto;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0 0 0 5px;
  border-radius: 50px !important;
}
.swal2-styled:i, .btn-circle:i {
  position: relative;
  top: -1px;
}

.btn-circle-xsm {
  width: 22px;
  height: 22px;
  line-height: 16px;
  font-size: 0.9rem;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 30px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 50px;
  font-size: 1.1rem;
  padding: 20px 0 0 5px;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

.btn-circle-title-xs {
  width: auto;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 540px) {
  .btn-circle-title-sm {
    width: auto;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 992px) {
  .btn-circle-title {
    width: auto;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
fieldset {
  background-color: #f0f4fa;
  padding: 10px;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
fieldset legend {
  background-color: #f0f4fa;
  width: auto;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
}

.swal2-styled {
  transition: all 0.2s ease;
  height: 40px !important;
  margin: 0 2% !important;
  padding: 0 20px !important;
  line-height: 0 !important;
  width: auto !important;
  min-width: 100px;
  background-color: #FFF !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}
.swal2-styled.swal2-confirm {
  border: 1px solid #0099FF !important;
  color: #0099FF !important;
}
.swal2-styled.swal2-confirm:hover {
  background-color: #0099FF !important;
}
.swal2-styled.swal2-cancel {
  border: 1px solid #AAA !important;
  color: #AAA !important;
}
.swal2-styled.swal2-cancel:hover {
  background-color: #AAA !important;
}
.swal2-styled:hover {
  color: #FFF !important;
}

.swal2-select {
  border-radius: 8px;
}

.banner .carousel .carousel-caption {
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  top: 0;
}
.banner .carousel .carousel-caption h1 {
  font-size: 2.6rem;
}
.banner .carousel .carousel-caption span {
  font-size: 1.8rem;
}
.banner .carousel .carousel-caption p {
  font-size: 1.3rem;
}
@media (max-width: 1199.98px) {
  .banner .carousel .carousel-caption h1 {
    font-size: 2.2rem;
  }
}
@media (max-width: 991.98px) {
  .banner .carousel .carousel-caption h1 {
    font-size: 2rem;
  }
  .banner .carousel .carousel-caption span {
    font-size: 1.6rem;
  }
  .banner .carousel .carousel-caption p {
    font-size: 1rem;
  }
}
@media (max-width: 767.98px) {
  .banner .carousel .carousel-caption h1 {
    font-size: 1.4rem;
  }
}
.banner .blackboard {
  background-color: #34302D;
  background-image: url("/assets/img/blackboard.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
}
.banner .blackboard p {
  font-family: cabin;
  font-size: 1.5em;
}

.banner-img {
  transform: all 0.2s;
  filter: contrast(80%) brightness(60%) sepia(10%);
}

.navbar {
  background-color: transparent;
}
.navbar.scrolled {
  background-color: #F8F9FA;
}
.navbar.shrink {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  margin: 2;
}
.navbar.shrink .navbar-brand {
  margin: 0;
  padding: 0;
}
.navbar.shrink .navbar-brand img {
  height: 39px;
  filter: brightness(100%);
}
.navbar.shrink .navbar-toggler {
  color: #3085D6 !important;
}
.navbar.shrink .nav-link {
  color: #636363 !important;
  margin: 0;
  padding: 5px;
}
.navbar.shrink .nav-link:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #3085D6 !important;
  background-color: rgba(255, 255, 255, 0);
}
.navbar .navbar-brand img {
  filter: grayscale(100%) brightness(300%);
}
.navbar .navbar-toggler {
  transition: unset;
  border: none !important;
  color: #FFF !important;
  font-size: 28px;
}
.navbar .nav-link {
  color: #E1E1E1 !important;
}
.navbar .nav-link:hover {
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #28A745 !important;
  background-color: rgba(0, 0, 0, 0.4);
}
.navbar .dropdown {
  transition: 0.2s ease;
}
.navbar .dropdown .dropdown-menu {
  padding: 2px;
  transition: 0.2s ease;
  color: #636363;
}
.navbar .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .navbar .navbar-collapse {
    background-color: #F8F9FA !important;
  }
  .navbar .nav-link {
    color: #636363 !important;
  }
}

.introduction .item img {
  width: 128px;
}

.owl-carousel .btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.owl-carousel .btn i {
  transform: scale(2);
}
.owl-carousel .btn.btn-left {
  left: -0.5rem;
}
.owl-carousel .btn.btn-right {
  right: -0.5rem;
}

.frame,
.frame .cover,
.frame .hover,
.frame-w,
.frame-w .cover,
.frame-w .hover {
  width: 222px;
  height: 222px;
}

.frame {
  cursor: pointer;
}
.frame .hover {
  background-color: rgba(255, 160, 0, 0.8);
  pointer-events: none;
}

@media (max-width: 1199.98px) {
  .frame,
  .frame .cover,
  .frame .hover {
    width: 186px;
    height: 186px;
  }
  .frame .cover img {
    width: 100px;
  }
  .frame .cover p,
  .frame .hover span {
    font-size: 15px;
  }
}
@media (max-width: 991.98px) {
  .frame,
  .frame .cover,
  .frame .hover {
    width: 230px;
    height: 173px;
  }
  .frame-w,
  .frame-w .cover,
  .frame-w .hover {
    width: 345px;
  }
  .frame-w img,
  .frame img {
    width: 100%;
  }
  .frame .cover img {
    width: 80px;
  }
  .frame .cover p,
  .frame .hover span {
    font-size: 14px;
  }
}
@media (max-width: 767.98px) {
  .frame,
  .frame .cover,
  .frame .hover {
    width: 170px;
    height: 173px;
  }
  .frame-w,
  .frame-w .cover,
  .frame-w .hover {
    width: 255px;
  }
  .frame .cover img {
    width: 80px;
  }
  .frame .cover p,
  .frame .hover span {
    font-size: 15px;
  }
}
@media (max-width: 575.98px) {
  .frame,
  .frame .cover,
  .frame .hover,
  .frame-w,
  .frame-w .cover,
  .frame-w .hover {
    width: 172px;
    height: 172px;
  }
  .frame .cover p,
  .frame .hover span {
    font-size: 14px;
  }
}
@media (max-width: 390px) {
  .frame,
  .frame .cover,
  .frame .hover,
  .frame-w,
  .frame-w .cover,
  .frame-w .hover {
    width: 228px;
    height: 228px;
  }
}
.modal-preview {
  width: 100%;
  height: 100%;
  overflow: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  display: none;
}
.modal-preview .modal-preview-bg {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
}
.modal-preview .modal-preview-bt {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin: -250px 0 0 360px;
  z-index: 1;
}
.modal-preview .modal-preview-content {
  width: 768px;
  height: 450px;
  position: relative;
  top: 50%;
  left: 50%;
  margin: -225px 0 0 -384px;
}

.method img {
  width: 40%;
}
@media (max-width: 575.98px) {
  .method {
    text-align: center;
  }
  .method img {
    display: inline-flex;
    width: 93%;
  }
}

.bt-video {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.bt-video:hover {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.about .about1 {
  margin-top: -130px;
  background-color: #3d7db3;
  padding-top: 140px !important;
}
@media (max-width: 575.98px) {
  .about .about1 {
    margin-top: -50px;
    padding-top: 70px !important;
  }
}
.about .about2 {
  background-color: #7b8ea1;
}
.about .about2 .department-title {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
}
.about .about2 .image-staff {
  width: 165px;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}

.jovensnotaveis,
.jardimnotavel,
.easychess,
.ucode {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.jovensnotaveis {
  background-image: url("/assets/img/banner_jovensnotaveis.png");
}

.jardimnotavel {
  background-image: url("/assets/img/banner_jardimnotavel.jpg");
  background-position: bottom;
}

.easychess {
  background-image: url("/assets/img/banner_easychess.jpg");
}

.ucode {
  background-image: url("/assets/img/banner_ucode.png");
}

.neuron {
  background-image: url("/assets/img/banner_neuron.png");
}

.testimonial {
  background-color: #7D4DD2;
}
.testimonial .bg-testimonial {
  background-image: url("/assets/img/bg_testimonial.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.jovensnotaveis img,
.jardimnotavel img,
.easychess img,
.ucode img,
.neuron img {
  border: 8px solid rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.jovensnotaveis img:hover,
.jardimnotavel img:hover,
.easychess img:hover,
.ucode img:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.method h1,
.method p {
  color: #4F4F4F;
}
.method .img-icon {
  width: initial;
  background-color: #FFF;
  margin-top: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  top: 25px;
  padding: 12px;
}

.social {
  background-color: #329BC3;
  background-image: url("/assets/img/texture1.png");
}
.social p {
  font-size: 1.2rem;
}

.carousel .carisma-logo {
  filter: brightness(150%) contrast(80%);
}
@media (max-width: 767.98px) {
  .carousel .carisma-logo {
    display: none;
  }
}

footer .card {
  margin: 0 auto;
  width: 118px;
  filter: grayscale(100%);
}
@media (min-width: 576px) {
  footer .card {
    width: 158px;
  }
}
@media (min-width: 768px) {
  footer .card {
    width: 108px;
  }
}
@media (min-width: 992px) {
  footer .card {
    width: 158px;
  }
}
footer .card:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  filter: grayscale(0);
}
footer .mn-bt-top {
  background-color: #FFF;
  position: relative;
  top: -25px;
}

.footer {
  bottom: -56px;
  height: 56px;
  background-color: #F8F9FA;
  font-family: Arial;
  font-size: 14px;
  transition: all 0.3s;
  padding: 16px 0;
}
.footer .bt {
  position: relative;
  top: -5px;
}
.footer p {
  margin: 0;
}

.login-form input[name=login],
.login-form input[type=text],
.login-form input[type=email] {
  margin-bottom: -2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.login-form input[name=password],
.login-form input[type=password] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.login-form .login-input {
  border-radius: 0;
}

.login-form input:focus {
  border: 2px solid #ccc;
  background-color: rgba(0, 0, 0, 0.05);
}

.login-form .g-recaptcha, .forget-form .g-recaptcha {
  display: inline-block;
  margin-bottom: 10px;
}

.bt {
  margin: 10px 0 10px 0;
}

@media (max-width: 576px) {
  .login-box .jn-col {
    width: 50%;
  }
}
@media (max-width: 359px) {
  .login-form .g-recaptcha {
    transform: scale(0.8);
    margin-left: -12px;
  }
}
.login-box-password {
  min-height: 320px !important;
}

.login-box-recaptcha {
  height: 380px;
}

.version {
  position: fixed;
  bottom: 10px;
  right: 10px;
}