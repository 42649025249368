.social {
	background-color: #329BC3;
	background-image: url('/assets/img/texture1.png');

    p {
        font-size: 1.2rem;
    }
}

.carousel {
    .carisma-logo {
        filter: brightness(150%) contrast(80%);

        @media (max-width: 767.98px) {
            display: none;
        }
    }
}