.method {
    img {
	    width: 40%;
    }

    @media (max-width: 575.98px) {
        text-align: center;

        img {
            display: inline-flex;
            width: 93%;
        }
    }
}

.bt-video {
	cursor: pointer;
	box-shadow: 0 0 5px rgba(0, 0, 0, .1);

    &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, .3);
    }
}