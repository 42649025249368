.jovensnotaveis,
.jardimnotavel,
.easychess,
.ucode {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.jovensnotaveis {
	background-image: url('/assets/img/banner_jovensnotaveis.png');
}

.jardimnotavel {
	background-image: url('/assets/img/banner_jardimnotavel.jpg');
	background-position: bottom;
}

.easychess {
	background-image: url('/assets/img/banner_easychess.jpg');
}

.ucode {
	background-image: url('/assets/img/banner_ucode.png');
}

.neuron {
	background-image: url('/assets/img/banner_neuron.png');
}

.testimonial {
	background-color: #7D4DD2;

    .bg-testimonial {
        background-image: url('/assets/img/bg_testimonial.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.jovensnotaveis img,
.jardimnotavel img,
.easychess img,
.ucode img,
.neuron img {
	border: 8px solid rgba(0, 0, 0, 0.05);
	transition: all 0.2s ease;
}

.jovensnotaveis img:hover,
.jardimnotavel img:hover,
.easychess img:hover,
.ucode img:hover {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.method {
    h1,
    p {
	    color: #4F4F4F;
    }

    .img-icon {
        width: initial;
        background-color: #FFF;
        margin-top: 100px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
        position: relative;
        top: 25px;
        padding: 12px;
    }
}