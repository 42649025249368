.frame,
.frame .cover,
.frame .hover,
.frame-w,
.frame-w .cover,
.frame-w .hover {
	width: 222px;
	height: 222px;
}

.frame {
    cursor: pointer;

    .hover {
        background-color: rgba(255, 160, 0, .8);
	    pointer-events: none;
    }
}

@media (max-width: 1199.98px) {
	.frame,
    .frame .cover,
    .frame .hover {
		width: 186px;
		height: 186px;
	}

	.frame {
        .cover {
            img { width: 100px; }
        }
	}

	.frame .cover p,
    .frame .hover span {
		font-size: 15px;
	}

}

@media (max-width: 991.98px) {
	.frame,
    .frame .cover,
    .frame .hover {
		width: 230px;
		height: 173px;
	}

	.frame-w,
    .frame-w .cover,
    .frame-w .hover {
		width: 345px;
	}

	.frame-w img,
    .frame img {
		width: 100%;
	}

	.frame {
        .cover {
            img {
		        width: 80px;
            }
        }
	}

	.frame .cover p,
    .frame .hover span {
		font-size: 14px;
	}

}

@media (max-width: 767.98px) {
	.frame,
    .frame .cover,
    .frame .hover {
		width: 170px;
		height: 173px;
	}

	.frame-w,
    .frame-w .cover,
    .frame-w .hover {
		width: 255px;
	}

	.frame .cover img {
		width: 80px;
	}

	.frame .cover p,
    .frame .hover span {
		font-size: 15px;
	}
}

@media (max-width: 575.98px) {
	.frame,
    .frame .cover,
    .frame .hover,
	.frame-w,
    .frame-w .cover,
    .frame-w .hover {
		width: 172px;
		height: 172px;
	}

	.frame .cover p,
    .frame .hover span {
		font-size: 14px;
	}
}

@media (max-width: 390px) {
	.frame,
    .frame .cover,
    .frame .hover,
	.frame-w,
    .frame-w .cover,
    .frame-w .hover {
		width: 228px;
		height: 228px;
	}
}