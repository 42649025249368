%button-circle {
	width: auto;
	width: 45px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	padding: 0 0 0 5px;
	border-radius: 50px !important;

    &:i {
        position: relative;
        top: -1px;
    }
}

.btn-circle {
	@extend %button-circle;	
}

.btn-circle-xsm {
	width: 22px;
	height: 22px;
	line-height: 16px;
	font-size: 0.9rem;
}

.btn-circle-sm {
	width: 35px;
	height: 35px;
	line-height: 30px;
	font-size: 0.9rem;
}

.btn-circle-lg {
	width: 55px;
	height: 55px;
	line-height: 50px;
	font-size: 1.1rem;
    padding: 20px 0 0 5px;
}

.btn-circle-xl {
	width: 70px;
	height: 70px;
	line-height: 70px;
	font-size: 1.3rem;
}

.btn-circle-title-xs {
	width: auto;
	border-radius: 50px;
	padding-left: 10px;
	padding-right: 10px;
}

@media (min-width: 540px) {
	.btn-circle-title-sm {
		width: auto;
		border-radius: 50px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 992px) {
	.btn-circle-title {
		width: auto;
		border-radius: 50px;
		padding-left: 10px;
		padding-right: 10px;
	}
}