.modal-preview {
	width: 100%; height: 100%;
	overflow: none;
	position: fixed;
	z-index: 1;
	left: 0; top: 0;
	display: flex;
	align-items: center;
	display: none;

    .modal-preview-bg {
        width: 100%; height: 100%;
        background-color: black;
        opacity: 0.7;
        position: fixed;
        left: 0; top: 0;
    }

    .modal-preview-bt {
        position: absolute;
        display: block;
        top: 50%; left: 50%;
        margin: -250px 0 0 360px;
        z-index: 1;
    }

    .modal-preview-content {
        width: 768px; height: 450px;
        position: relative;
        top:50%; left: 50%;
        margin: -225px 0 0 -384px;
    }
}
