@font-face {
	font-family: calibri;
	src:url('fonts/Calibri.ttf');
}

@font-face {
	font-family: cabin;
	src:url('fonts/CabinSketch-Regular.otf') format("opentype");
}

* {
	transition: all .2s;
}

html, body {
	font-family: calibri;
	font-size: 18px;
}

.opacity-0 {
	opacity: 0;
}
.scale-0 {
	transform: scale(0);
}

article {
	background-color: #ECF0F1;
}

.mn-bg-blue {
	background-color: #2CB7F0;
}
.mn-bg-blue-dark {
	background-color: #0F98E7;
}
.mn-big-number {
	font-size: 50px;
}
.mn-bg-icons {
	background-color: #85D0F5;
}

.btn-table {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
	.animated-checkbox.checkbox-xl-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}
}
  
@media (max-width: 576px) {
	.animated-checkbox.checkbox-xs-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}
}

form {
	margin-block-end: 0;
}

.form-group.required label:after {
	content:"*";
	color:red;
	font-weight: bold;
}

.g-recaptcha {
	display: inline-block;
}

.filter-footer {
	width: 100%;
	bottom: 0;
	position: absolute;
}

.bg-overlay {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1050;
	visibility: hidden;
	opacity: 0.0;
	overflow: hidden;
	text-align: center;
	transition: 0.3s ease;

	img {
		position: fixed;
		margin: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.bg-overlay-show {
	visibility: visible;
	opacity: 1.0;
}

label {
	width: max-content;
}