.banner {
    .carousel {
        .carousel-caption {
            text-shadow: 0 1px 4px rgba(0, 0, 0, .3);
            top: 0;

            h1 { font-size: 2.6rem; }
            span { font-size: 1.8rem; }
            p { font-size: 1.3rem; }

            @media (max-width: 1199.98px) {
                h1 { font-size: 2.2rem; }
            }

            @media (max-width: 991.98px) {
                h1 { font-size: 2rem; }
                span { font-size: 1.6rem; }
                p { font-size: 1rem; }
            }

            @media (max-width: 767.98px) {
                h1 { font-size: 1.4rem; }
            }
        }
    }

    .blackboard {
        background-color: #34302D;
        background-image: url('/assets/img/blackboard.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: auto 100%;

        p {
            font-family: cabin;
            font-size: 1.5em;
        }
    }
}

.banner-img {
    transform: all .2s;
    filter: contrast(80%) brightness(60%) sepia(10%);
}