footer {
    .card {
        margin: 0 auto;
        width: 118px;
        filter: grayscale(100%);
        
        @media (min-width: 576px) {
            width: 158px;
        }
        
        @media (min-width: 768px) {
            width: 108px;
        }
        
        @media (min-width: 992px) {
            width: 158px;
        }

        &:hover {
            box-shadow: 0px 3px 5px rgba(0, 0, 0, .2);
	        filter: grayscale(0);
        }
    }

    .mn-bt-top {
        background-color: #FFF;
        position: relative;
        top: -25px;
    }
}